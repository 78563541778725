<template>
  <div class="card card-custom gutter-b">
    <div class="col-md-12">
      <b-form @submit.prevent.stop="onSubmit">
        <div class="card-header">
          <h4 class="title-card-custom">Entri Dokumentasi/Produk Hukum</h4>
        </div>
        <div class="card-body">
          <b-form-group
            id="input-group-1"
            label="Tahun"
            label-for="year"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label-class="required"
          >
            <b-form-input
              id="year"
              v-model="form.year"
              type="text"
              placeholder="Tahun"
              :state="validateState('year')"
            ></b-form-input>

            <b-form-invalid-feedback id="input-1-live-feedback" v-if="!$v.form.year.required">Tahun wajib diisi</b-form-invalid-feedback>
            <b-form-invalid-feedback id="input-1-live-feedback" v-if="!$v.form.year.maxLength">Maksimal 4 karakter</b-form-invalid-feedback>
            <b-form-invalid-feedback id="input-1-live-feedback" v-if="!$v.form.year.minLength">Minimal 4 karakter</b-form-invalid-feedback>
            <b-form-invalid-feedback id="input-1-live-feedback" v-if="!$v.form.year.numeric">Wajib berupa angka tahun (19xx/20xx)</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Jenis Produk Hukum"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label-for="jenis"
            label-class="required"
            >
            <b-form-select v-model="form.type" :options="types" class="mb-3">
              <template #first>
                <b-form-select-option :value="null" disabled
                >-- Pilih salah satu --</b-form-select-option
                >
              </template>
            </b-form-select>
            
            <b-form-input
              id="jenis"
              v-model="form.jenis"
              placeholder="Masukkan jenis produk hukum"
              v-if="form.type === 'O'"
              v-show="form.type"
              :state="validateState('type')"
            ></b-form-input>

            <b-form-invalid-feedback id="input-2-live-feedback" v-if="!$v.form.type.required">Jenis Produk Hukum wajib diisi</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Judul"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label-for="title"
            label-class="required"
            >
            <b-form-textarea
              id="title"
              v-model="form.judul"
              placeholder="Judul"
              rows="3"
              max-rows="6"
              :state="validateState('judul')"
            ></b-form-textarea>

            <b-form-invalid-feedback id="input-1-live-feedback" v-if="!$v.form.judul.required">Judul wajib diisi</b-form-invalid-feedback>
          </b-form-group>

          <!--          <b-form-group
            id="input-group-4"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label-for="product"
            label="Nama Produk Hukum"
          >
            <b-form-textarea
              id="textarea"
              v-model="form.nama"
              placeholder="Nama Produk Hukum"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>-->

          <b-form-group
            id="input-group-5"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Upload Judul"
            label-for="input-5"
            description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
            label-class="required"
          >
            <b-form-file
              placeholder="Pilih File"
              drop-placeholder="Drop file di sini..."
              accept=".pdf, .doc, .docx"
              v-model="form.path"
              browse-text="Cari"
              multiple
              :file-name-formatter="formatNames"
              @change="
                validateFileTypeGeneral($event);
                fileSizeChecker('10', $event);
              "
            ></b-form-file>
          </b-form-group>

          <b-form-group
            id="input-group-6"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Sinopsis"
            label-for="input-6"
          >
            <b-form-textarea
              id="sinopsis"
              v-model="form.sinopsis"
              placeholder="Sinopsis"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>

          <h6 class="title-card-custom">Update Status</h6>
          <hr />
          <div class="mb-8"></div>

          <b-form-group
            id="input-group-status"
            label="Status"
            label-for="status"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
          >
            <b-form-select v-model="form.status" :options="status">
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Pilih Salah Satu --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            id="input-group-deskripsi"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Judul"
            label-for="input-6"
          >
            <b-form-textarea
              id="deskripsi"
              v-model="form.deskripsi"
              placeholder="Judul"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            id="input-group-url"
            label="URL"
            label-for="url"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
          >
            <b-form-input
              id="url"
              v-model="form.url"
              type="text"
              placeholder="URL"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-6"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Tanggal Progres"
            label-for="tanggal"
          >
            <b-form-datepicker
              id="tanggal"
              v-model="form.tanggal"
              class="mb-2"
              placeholder="Pilih tanggal progres"
              locale="id"
            ></b-form-datepicker>
          </b-form-group>

          <b-form-group
            id="input-group-5"
            label-cols-sm="4"
            label-cols-lg="3"
            content-cols-sm="6"
            content-cols-lg="7"
            label="Upload Produk"
            label-for="input-5"
            description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
          >
            <b-form-file
              placeholder="Pilih File"
              drop-placeholder="Drop file di sini..."
              accept=".pdf, .doc, .docx"
              v-model="form.path_product"
              browse-text="Cari"
              multiple
              :file-name-formatter="formatNames"
              @change="
                validateFileTypeGeneral($event);
                fileSizeChecker('10', $event);
              "
            ></b-form-file>
          </b-form-group>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-3">
              <button
                class="ml-2 btn btn-secondary"
                style="border-radius: 3px"
                type="reset"
                @click="$router.back()"
              >
                Batal
              </button>
              <button class="ml-2 btn btn-submit" type="submit">Kirim</button>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { handleErrors } from "@/core/appUtil/util";
import Swal from "sweetalert2";
import moment from "moment";
import {
  fileSizeChecker,
  validateFileTypeGeneral,
} from "@/core/helpers/functions";
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  numeric,
} from "vuelidate/lib/validators";

let token = "";

const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete",
  },
  buttonsStyling: false,
});

export default {
  mixins: [validationMixin, fileSizeChecker, validateFileTypeGeneral],
  name: "dokumentasi",
  data() {
    return {
      form: {
        year: "",
        status: "",
        judul: "",
        nama: "",
        sinopsis: "",
        deskripsi: "",
        url: "",
        path: null,
        path_product: null,
        jenis: "",
        type: null,
        tanggal: "",
      },
      status: [
        {
          text: "Mengubah",
          value: "Mengubah",
        },
        {
          text: "Mencabut",
          value: "Mencabut",
        },
        {
          text: "Uji Materi MA",
          value: "Uji Materi MA",
        },
      ],
      types: [],
      show: true,
    };
  },
  validations: {
    form: {
      year: {
        required,
        numeric,
        maxLength: maxLength(4),
        minLength: minLength(4),
      },
      type: {
        required,
      },
      judul: {
        required,
      },
    },
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then((access_token) => {
      token = access_token;
    });
    fetch(
      encodeURI(
        process.env.VUE_APP_URL_LOCAL + `/master/jenis-perundangan?take=100`
      ),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + token,
        },
      }
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then((items) => {
        const detailMenu = items.data;
        let selectedOptions;
        selectedOptions = [];
        detailMenu.map((i) => {
          return selectedOptions.push({
            text: i.name,
            value: i.id,
          });
        });
        this.types = selectedOptions;
      });
  },
  methods: {
    uppercase() {
      this.form.judul = this.form.judul.toUpperCase();
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          if (this.form.path !== null) {
            let dataUpload = new FormData();
            for (const file of this.form.path) {
              dataUpload.append("files", file, file.name);
            }
            await fetch(
              encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`),
              {
                method: "POST",
                headers: {
                  Authorization: "bearer " + token,
                },
                body: dataUpload,
              }
            )
              .then(handleErrors)
              .then((response) => response.json())
              .then(async (dataHasilUpload) => {
                let documentation = {
                  jenis_perundangan_id: this.form.type,
                  judul: this.form.judul,
                  nama: this.form.nama,
                  produk_path: dataHasilUpload.data.join(";"),
                  sinopsis: this.form.sinopsis,
                  tahun: parseInt(this.form.year),
                };
                await fetch(
                  encodeURI(process.env.VUE_APP_URL_LOCAL + `/dokumentasi`),
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "bearer " + token,
                    },
                    body: JSON.stringify(documentation),
                  }
                )
                  .then(handleErrors)
                  .then((response) => response.json())
                  .then(async (dataHasilProgress) => {
                    if (this.form.path_product !== null) {
                      let dataUpload = new FormData();
                      for (const file of this.form.path_product) {
                        dataUpload.append("files", file, file.name);
                      }
                      await fetch(
                        encodeURI(
                          process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`
                        ),
                        {
                          method: "POST",
                          headers: {
                            Authorization: "bearer " + token,
                          },
                          body: dataUpload,
                        }
                      )
                        .then(handleErrors)
                        .then((response) => response.json())
                        .then(async (result) => {
                          let data = {
                            dokumentasi_id: dataHasilProgress.data.id,
                            path: result.data.join(";"),
                            status: this.form.status,
                            tentang: this.form.deskripsi,
                            url: this.form.url,
                            tanggal: this.form.tanggal
                              ? moment
                                  .utc(this.form.tanggal, "YYYY-MM-DD")
                                  .format()
                              : null,
                          };

                          await fetch(
                            encodeURI(
                              process.env.VUE_APP_URL_LOCAL +
                                `/dokumentasi-progress`
                            ),
                            {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: "bearer " + token,
                              },
                              body: JSON.stringify(data),
                            }
                          )
                            .then(handleErrors)
                            .then((response) => response.json())
                            .then(async (result) => {
                              Swal.fire({
                                position: "center",
                                icon: result.status,
                                title: "Data berhasil ditambahkan",
                                showConfirmButton: false,
                                timer: 1500,
                              });
                              this.onReset();
                              await this.$router.push({
                                name: "TabelDokumentasi",
                              });
                            })
                            .catch(() => {
                              Swal.fire({
                                position: "center",
                                icon: "error",
                                title:
                                  "Data gagal ditambahkan, silahkan periksa isian form dan coba lagi",
                                showConfirmButton: false,
                                timer: 2000,
                              });
                            });
                        });
                    } else {
                      await Swal.fire({
                        position: "center",
                        icon: dataHasilProgress.status,
                        title: "Data berhasil ditambahkan",
                        showConfirmButton: false,
                        timer: 1500,
                      });

                      this.onReset();
                      await this.$router.push({
                        name: "TabelDokumentasi",
                      });
                    }
                    /*else {
                      let documentation = {
                        dokumentasi_id: dataHasilProgress.data.id,
                        status: this.form.status,
                        tentang: this.form.deskripsi,
                        url: this.form.url
                      };
                      await fetch(
                        encodeURI(
                          process.env.VUE_APP_URL_LOCAL +
                            `/dokumentasi-progress`
                        ),
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: "bearer " + token
                          },
                          body: JSON.stringify(documentation)
                        }
                      )
                        .then(handleErrors)
                        .then(response => response.json())
                        .then(async result => {
                          Swal.fire({
                            position: "center",
                            icon: result.status,
                            title: "Data berhasil ditambahkan",
                            showConfirmButton: false,
                            timer: 1500
                          });
                          this.onReset();
                          await this.$router.push({
                            name: "TabelDokumentasi"
                          });
                        });
                    }*/
                  });
              })
              .catch(() => {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title:
                    "Unggah file judul gagal, silahkan periksa isian form dan coba lagi",
                  showConfirmButton: false,
                  timer: 2000,
                });
              });
          } else {
            let documentation = {
              jenis_perundangan_id: this.form.type,
              judul: this.form.judul,
              nama: this.form.nama,
              sinopsis: this.form.sinopsis,
              tahun: parseInt(this.form.year),
            };
            await fetch(
              encodeURI(process.env.VUE_APP_URL_LOCAL + `/dokumentasi`),
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "bearer " + token,
                },
                body: JSON.stringify(documentation),
              }
            )
              .then(handleErrors)
              .then((response) => response.json())
              .then((dataHasilProgress) => {
                Swal.fire({
                  position: "center",
                  icon: dataHasilProgress.status,
                  title: "Data berhasil ditambahkan",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.onReset();
                this.$router.push({ name: "TabelDokumentasi" });
              })
              .catch(() => {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title:
                    "Data gagal ditambahkan, silahkan periksa isian form dan coba lagi",
                  showConfirmButton: false,
                  timer: 2000,
                });
              });
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
    onReset() {
      // Reset our form values
      this.form.year = "";
      this.form.status = "";
      this.form.judul = "";
      this.form.deskripsi = "";
      this.form.nama = "";
      this.form.jenis = "";
      this.form.file = null;
      this.form.sinopsis = "";
      this.form.type = null;
      this.form.tanggal = "";
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} file terpilih`;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getErrors(validation) {
      const errors = [];
      for (const key in validation.$params) {
        if (!validation[key]) {
          errors.push(validation.$params[key].message);
        }
      }
      return errors;
    },
  },
};
</script>
